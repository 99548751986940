import {
  Controller
} from '@hotwired/stimulus';

// Connects to data-controller="rich-text"
export default (class extends Controller {
  connect() {
    var i, j, len, len1, link, ref, ref1, results;
    ref = this.element.querySelectorAll('a.js-direct-download');
    for (i = 0, len = ref.length; i < len; i++) {
      link = ref[i];
      // Disable Turbo in order to handle unknown file extensions
      link.setAttribute('data-turbo', false);
    }
    ref1 = this.element.querySelectorAll('a:not(.js-direct-download)');
    results = [];
    for (j = 0, len1 = ref1.length; j < len1; j++) {
      link = ref1[j];
      link.setAttribute('data-turbo-frame', '_top');
      results.push(link.target = '_blank');
    }
    return results;
  }

});
