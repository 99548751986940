import * as bootstrap from 'bootstrap';

import hotkeys from 'hotkeys-js';

document.addEventListener('turbo:load', function() {
  var _toggleSidebarMenu;
  // Set focus to site-wide search
  hotkeys('/', function(event, _handler) {
    event.preventDefault();
    // Ignore shortcuts if a modal is open
    if (document.querySelector('body.modal-open')) {
      return;
    }
    return document.getElementById('site_search_query').focus();
  });
  // Open or close the Offcanvas Left Site Sidebar menu
  // TODO: update pro tips!
  hotkeys('m+l', function(event, _handler) {
    var siteSidebarOffcanvas;
    // _toggleSidebarMenu('left') # TODO: use this
    event.preventDefault();
    // Ignore shortcuts if a modal is open
    if (document.querySelector('body.modal-open')) {
      return;
    }
    // TODO: scope this to "left"?
    siteSidebarOffcanvas = bootstrap.Offcanvas.getOrCreateInstance('.js-menu-shortcut');
    if (siteSidebarOffcanvas._element.classList.contains('show')) {
      return siteSidebarOffcanvas.hide();
    } else {
      return siteSidebarOffcanvas.show();
    }
  });
  // Open or close the Offcanvas Right Site Sidebar menu
  hotkeys('m+r', function(event, _handler) {
    return _toggleSidebarMenu('right');
  });
  // Private

  // TODO: rename "side"?
  return _toggleSidebarMenu = function(side) {
    var siteSidebarOffcanvas;
    event.preventDefault();
    // Ignore shortcuts if a modal is open
    if (document.querySelector('body.modal-open')) {
      return;
    }
    siteSidebarOffcanvas = bootstrap.Offcanvas.getOrCreateInstance(`.js-${side}-menu-shortcut`);
    if (siteSidebarOffcanvas._element.classList.contains('show')) {
      return siteSidebarOffcanvas.hide();
    } else {
      return siteSidebarOffcanvas.show();
    }
  };
});
